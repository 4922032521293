var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "base" },
    [
      _c("div", { staticClass: "card-title" }, [_vm._v("报修描述")]),
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "card-cell" }, [
          _vm._m(0),
          _c("div", { staticClass: "cell-content" }, [
            _vm._v(_vm._s(_vm.dataObj.title))
          ])
        ]),
        _c("div", { staticClass: "card-cell" }, [
          _vm._m(1),
          _c("div", { staticClass: "cell-content" }, [
            _vm._v(_vm._s(_vm.dataObj.position))
          ])
        ]),
        _c("div", { staticClass: "card-cell textarea-cell" }, [
          _vm._m(2),
          _c("div", { staticClass: "cell-content" }, [
            _vm._v(_vm._s(_vm.dataObj.details))
          ])
        ]),
        _c("div", { staticClass: "card-cell" }, [
          _vm._m(3),
          _c("div", { staticClass: "cell-content" }, [
            _vm._v(_vm._s(_vm.dataObj.contacts))
          ])
        ]),
        _c("div", { staticClass: "card-cell" }, [
          _vm._m(4),
          _c("div", { staticClass: "cell-content" }, [
            _vm._v(_vm._s(_vm.dataObj.contactsMobile))
          ])
        ])
      ]),
      _c("div", { staticClass: "card-title" }, [_vm._v("现场照片")]),
      _c("div", { staticClass: "card" }, [
        _vm.fileList.length > 0
          ? _c(
              "div",
              { staticClass: "card-cell imgs-cell" },
              _vm._l(_vm.fileList, function(item, index) {
                return _c("img", {
                  key: index,
                  staticClass: "up-img",
                  attrs: { src: item },
                  on: {
                    click: function($event) {
                      return _vm.showImg(item)
                    }
                  }
                })
              }),
              0
            )
          : _vm._e(),
        _vm.fileList.length == 0
          ? _c("div", { staticClass: "card-cell imgs-cell" }, [
              _c("div", { staticClass: "no-img" }, [_vm._v("未上传现场照片")])
            ])
          : _vm._e()
      ]),
      _vm.dataObj.repairStatus == "1"
        ? [
            _c("div", { staticClass: "card-title" }, [_vm._v("报修处理")]),
            _c("div", { staticClass: "card" }, [
              _c("div", { staticClass: "card-cell textarea-cell" }, [
                _vm._m(5),
                _c("div", { staticClass: "cell-content" }, [
                  _vm._v(_vm._s(_vm.dataObj.handleExplain))
                ])
              ]),
              _vm._m(6),
              _vm.handleImagePath.length > 0
                ? _c(
                    "div",
                    { staticClass: "card-cell imgs-cell" },
                    _vm._l(_vm.handleImagePath, function(item, index) {
                      return _c("img", {
                        key: index,
                        staticClass: "up-img",
                        attrs: { src: item },
                        on: {
                          click: function($event) {
                            return _vm.showImg(item)
                          }
                        }
                      })
                    }),
                    0
                  )
                : _vm._e(),
              _vm.handleImagePath.length == 0
                ? _c("div", { staticClass: "card-cell imgs-cell" }, [
                    _c("div", { staticClass: "no-img" }, [
                      _vm._v("未上传维修照片")
                    ])
                  ])
                : _vm._e(),
              _c("div", { staticClass: "card-cell" }, [
                _vm._m(7),
                _c("div", { staticClass: "cell-content" }, [
                  _vm.dataObj.isParts == 0
                    ? _c("span", [_vm._v("否")])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.dataObj.isParts == 1
                    ? _c("span", [_vm._v("是")])
                    : _vm._e()
                ])
              ]),
              _vm.dataObj.isParts == 1
                ? _c("div", { staticClass: "card-cell" }, [
                    _vm._m(8),
                    _c("div", { staticClass: "cell-content" }, [
                      _vm._v(_vm._s(_vm.dataObj.consumables))
                    ])
                  ])
                : _vm._e(),
              _c("div", { staticClass: "card-cell" }, [
                _vm._m(9),
                _c("div", { staticClass: "cell-content" }, [
                  _vm._v(_vm._s(_vm.dataObj.updateTime))
                ])
              ])
            ]),
            _c("div", { staticClass: "footer" })
          ]
        : _vm._e(),
      _c(
        "mt-popup",
        {
          model: {
            value: _vm.showImgFlag,
            callback: function($$v) {
              _vm.showImgFlag = $$v
            },
            expression: "showImgFlag"
          }
        },
        [_c("img", { staticClass: "big-img", attrs: { src: _vm.tempImgUrl } })]
      )
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "cell-title" }, [
      _c("span", { staticClass: "required" }, [_vm._v("*")]),
      _c("span", [_vm._v("设备名称")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "cell-title" }, [
      _c("span", { staticClass: "required" }, [_vm._v("*")]),
      _c("span", [_vm._v("报修位置")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "cell-title" }, [
      _c("span", { staticClass: "required" }, [_vm._v("*")]),
      _c("span", [_vm._v("详情描述")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "cell-title" }, [
      _c("span", { staticClass: "required" }, [_vm._v("*")]),
      _c("span", [_vm._v("报修联系人")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "cell-title" }, [
      _c("span", { staticClass: "required" }, [_vm._v("*")]),
      _c("span", [_vm._v("联系电话")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "cell-title" }, [
      _c("span", { staticClass: "required" }, [_vm._v("*")]),
      _c("span", [_vm._v("维修描述")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "imgs-cell-title" }, [
      _c("span", { staticClass: "required" }, [_vm._v("*")]),
      _c("span", [_vm._v("维修照片")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "cell-title" }, [
      _c("span", { staticClass: "required" }, [_vm._v("*")]),
      _c("span", [_vm._v("是否使用配件")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "cell-title" }, [
      _c("span", { staticClass: "required" }, [_vm._v("*")]),
      _c("span", [_vm._v("使用配件")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "cell-title" }, [
      _c("span", { staticClass: "required" }, [_vm._v("*")]),
      _c("span", [_vm._v("完成时间")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }